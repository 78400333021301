import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="Testimonials" />
    {/* <Container className="d-lg-flex heroWidth">
        <div lg={6} className="">
          <h1></h1>
          
        </div>
        <div className="">
          <StaticImage
            src="../images/child-white-shirt.jpg"
            loading="eager"
            width={350}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Child in white shirt talking into a microphone"
          />
        </div>
    </Container> */}
    <div className="masthead mb-5">
      <Container className="singleCol">
        <h1>Testimonials</h1>
      </Container>
    </div>
    <Container className="singleCol">
      <blockquote>
        <p>We HIGHLY recommend Kris! She is incredible with children and truly an expert at her craft. She has worked closely with our daughter (4 years old) and therefore helped her to make significant improvements in her speech therapy in a very short amount of time. Kris immediately formed a wonderful bond with our daughter and then the results followed quickly. I can’t say enough amazing things about Kris – she’s absolutely terrific.</p>
        <cite>Sheila S., Larkspur, California</cite>
      </blockquote>

      <blockquote>
        <p>Kris has been delightful to work with and was absolutely the perfect person to help my 13 year old son with his lisp. She made their meetings pleasant and productive and helped him learn techniques that corrected his lisp in a very short time. Because of her kind and knowledgeable guidance, he is now more confident and self assured as a result.</p>
        <cite>Elaine N., Tiburon, California</cite>
      </blockquote>

      <blockquote>
        <p>These guys have been amazing. We’ve worked with two speech therapists within this group in the last 2 years and both were fantastic. When things had to switch to virtual, I was not totally confident our then 3 year old would handle it well. Aida however worked her magic and within a really short time had my daughter so engaged and looking forward to her sessions. In addition to increased articulation and verbalization, I’ve seen a huge increase in confidence with my little girl – she is so proud of the work she does with her special buddy Aida. Aida is pretty much my hero – she makes sessions so fun and entertaining. She also does an excellent job communicating with me – helping me understand how best to support my daughter. All in all I really couldn’t endorse these guys any more!!</p>
        <cite>Daphne N., Coatesville, Pennsylvania</cite>
      </blockquote>

      <blockquote>
        <p>Our 6 year old son has been working with Dan via virtual sessions for the past few months and we couldn’t be happier with the results! Our son is excited to meet with Dan for each session and Dan is able to keep him engaged the entire time. Our son is finally showing an interest in reading and making great progress with his phonemic awareness. We truly couldn’t be more pleased with the advancement our son has made in a short period of time. Many thanks to Kris and Dan! </p>
        <cite>Kate W., Denver, Colorado</cite>
      </blockquote>

    </Container>
    


  </Layout>
)

export default IndexPage
